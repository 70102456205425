.wrapper {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
  padding-top: 7px;
  padding-bottom: 7px;
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
  cursor: pointer;
  height: 38px;
}
.iconWrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: rgb(0, 0, 0, 0.7);
  height: 40px;
  padding: 7px;
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
}
.iconLinkWrapper {
  background-color: rgb(0, 0, 0, 0.7);
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.iconLinkWrapperRounded {
  background-color: rgb(0, 0, 0, 0.7);
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
}
.socialIcons {
  font-size: 25px;
}
.facebookIcon {
  //color: #3b5998;
  color: white;
}
.twitterIcon {
  // color: #1da1f2;
  color: white;
}
.instagramIcon {
  // background: radial-gradient(
  //   circle at 30% 107%,
  //   #fdf497 0%,
  //   #fdf497 5%,
  //   #fd5949 45%,
  //   #d6249f 60%,
  //   #285aeb 90%
  // );
  // -webkit-background-clip: text;
  // /* Also define standard property for compatibility */
  // background-clip: text;
  // -webkit-text-fill-color: transparent;
  color: white;
  margin-right: 12px;
  margin-left: 8px;
}
