.stickyHeader {
  height: 150px;
  position: fixed;
  top: 0;
  z-index: 1001;
  width: 100%;
  /* background-color: #262627; */
  background-color: rgba(0, 0, 0, 0.6);
}

.header {
  height: 150px;
  width: 100%;
  /* background-color: #0c0e0f; */
  background-color: rgba(0, 0, 0, 0.6);
}

.topHeader {
  height: 40px;
  max-height: 40px;
  border-color: rgba(255, 255, 255, 0.15);
  border-bottom-width: 1px;
  border-bottom-style: solid;
  width: 95%;
}
.topRightItem {
  display: flex;
  align-items: center;
  font-size: 12px;
}
.topRightItem:after {
  content: "";
  display: block;
  height: 18px;
  border-right: 1px solid;
  border-color: rgba(255, 255, 255, 0.15);
  margin-right: 9px;
  margin-left: 9px;
}
.topBarIcon {
  font-size: 15px;
  color: #fff !important;
  margin-right: 5px;
}
.topBarText {
  font-size: 14px;
  font-weight: 500;
  text-transform: uppercase;
}
.topLeftBarText {
  font-size: 12px;
  font-weight: 400;
  text-transform: uppercase;
}
