// .menuWrapper {
//   position: absolute;
//   top: 25px;
//   right: 15px;
// }
.menuIconDiv {
  position: absolute;
  right: 15px;
  top: 100px;
  z-index: 1;
  width: 30px;
  cursor: pointer;
}
.mainMenu {
  padding: 0;
  margin: 0;
}
.menuUl {
  display: flex;
  // remove this for horizontal
  flex-direction: column;

  justify-content: space-evenly;
  color: black;
  background-color: white;

  //width:100%; for horizontal
  width: 200px;
  margin-right: 10px;
  margin-left: 10px;
  position: absolute;
  top: 135px;

  right: 5px;
  z-index: 20;
  list-style: none;
}

.menuUl li {
  padding: 10px 0px;
  font-size: 18px;
  cursor: pointer;
  color: #c0c0c0;
}
.menuListIcon {
  color: black;
  margin-right: 5px;
}

.menuUl li:hover {
  // background-color: #000000;
  color: #fff;
}
.closeIcon {
  font-size: 30px;
  color: white;
  cursor: pointer;
}

.openIcon {
  font-size: 30px;
  color: white;
  cursor: pointer;
}
