.enterBtn {
  width: 350px;
  height: 60px;
  color: #fff;
  background-color: transparent;
  border-right: 0px;
  border-left: 0px;
  border-top: 1px solid #fff;
  border-bottom: 1px solid #fff;
  cursor: pointer;
}

.enterBtn:hover {
  border-top: 1px solid #ff0000;
  border-bottom: 1px solid #ff0000;
  color: red;
}
