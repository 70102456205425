@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("./styles/bgColors.css");
@import url("./styles/textColors.css");
@import url("./styles/header.css");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
*,
*:before,
*:after {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

html * {
  font-size: 16px;
  line-height: 1.625;
  font-family: "Montserrat", sans-serif;
}

/* box-sizing will stay consistant regardless of padding/margin */

/* 
const variant = {
  hidden: {
    x:"-100vw"
  },
  visible: {
x: 0,
  transition: {
    delayL 0.5,
    when: "beforeChildren" // this is animate parent before the child. without using delay
  }
  }
}

staggerChildren: 0.2 //this is will animate each child after the duration of 0.2 sec.
 */
